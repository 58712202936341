const FPS = 100
const DEBOUNCE = 40
const BOUNCE_TIME = Math.round(1000 / DEBOUNCE)
const FRAME_TIME = Math.round(1000 / FPS)
let cursor = null
const cursorPos = {
	x: -150,
	y: -150
}

toHomePage = () => {
	document.location.href = '/'
}

getCurrentTheme = () => {
	const themeLink = document.querySelector('#theme')
	if (!themeLink) return
	return themeLink.getAttribute('href').match('light') ? 'light' : 'dark'
}

setRightTheme = () => {
	const themeLink = document.querySelector('#theme')
	if (!themeLink) return
	themeLink.setAttribute(
		'href',
		themeLink.getAttribute('href').replace(getCurrentTheme(), localStorage.getItem('theme'))
	)
}

isTouchDevice = () => {
	return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
}

updatePosition = async (target, x, y) => {
	target.style.transform = `translate(${x}px, ${y}px)`
}

throttledCursor = function (delay, fn) {
	let lastCall = 0
	return function (...args) {
		const now = new Date().getTime()
		if (now - lastCall < delay) {
			return
		}
		lastCall = now
		return fn(...args)
	}
}

requestInterval = function (fn, delay) {
	var requestAnimFrame = (function () {
			return (
				window.requestAnimationFrame ||
				function (callback, element) {
					window.setTimeout(callback, 1000 / 60)
				}
			)
		})(),
		start = new Date().getTime(),
		handle = {}
	function loop() {
		handle.value = requestAnimFrame(loop)
		var current = new Date().getTime(),
			delta = current - start
		if (delta >= delay) {
			fn.call()
			start = new Date().getTime()
		}
	}
	handle.value = requestAnimFrame(loop)
	return handle
}

parallax = (e, images) => {
	const ratio = 250
	;[...images].forEach((move) => {
		const moving_value = move.getAttribute('data-value')
		const x = (e.clientX * moving_value) / ratio
		const y = (e.clientY * moving_value) / ratio
		window.requestAnimationFrame(() => {
			updatePosition(move, x, y)
		})
	})
}

frameTick = async () => {
	const cursorSize = cursor.offsetHeight / 2
	cursor.style.transform = `translate(${cursorPos.x - cursorSize}px, ${cursorPos.y - cursorSize}px)`
	window.requestAnimationFrame(frameTick)
}

cursorMove = async (e) => {
	cursorPos.x = e.clientX
	cursorPos.y = e.clientY
}

initCursor = () => {
	const mobileLink = document.querySelector('#mobile-link')
	cursor = document.querySelector('#cursor')
	if (isTouchDevice()) {
		document.body.classList.toggle('no-hover', true)
	}
	if (!cursor || isTouchDevice()) return
	if (mobileLink) mobileLink.style.display = 'none'
	const frameInterval = FRAME_TIME

	cursor.classList.toggle('cursor', true)
	cursor.classList.toggle('cursor-404', true)
	document.body.classList.toggle('custom-cursor')

	const cursorHandler = throttledCursor(BOUNCE_TIME, cursorMove)

	window.addEventListener('click', toHomePage)
	window.addEventListener('mousemove', cursorHandler, { passive: true })

	requestInterval(frameTick, frameInterval)
}

document.addEventListener('readystatechange', (event) => {
	if (event.target.readyState === 'interactive') {
		setRightTheme()
	} else if (event.target.readyState === 'complete') {
		const vh = window.innerHeight * 0.01
		const images = document.querySelectorAll('.img-404')
		const paralaxHandler = throttledCursor(70, (e) => {
			parallax(e, images)
		})
		document.documentElement.style.setProperty('--vh', `${vh}px`)

		window.addEventListener('mousemove', paralaxHandler, { passive: true })
		initCursor()
	}
})
